<template>
  <div id="app">
    <Nav :appAreas="appAreas" :mapPages="mapPages" :selectedAppArea.sync="selectedAppArea" :selectedMapPage.sync="selectedMapPage"/>
    <LeafletMap :mapPageData="selectedMapPageData" />
  </div>
</template>

<script>
import LeafletMap from './components/LeafletMap.vue'
import Nav from './components/Nav.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vue from 'vue'
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'leaflet/dist/leaflet.css'
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default {
  name: 'App',
  components: {
    LeafletMap,
    Nav
  },
  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      appAreas: [],
      mapPages: [],
      selectedAppArea: null,
      selectedMapPage: null,
      selectedMapPageData: {}
    }
  },
  mounted () {
    axios
      .get(this.apiUrl + '/map/appAreas')
      .then(response => (this.appAreas = response.data));
  },
  watch: {
    selectedAppArea: function (appArea) {
      axios
        .get(this.apiUrl + '/map/pages/byAppArea/' + appArea)
        .then(response => {
          this.mapPages = response.data;
        })
    },
    selectedMapPage: function (mapPageId) {
      axios
        .get(this.apiUrl + '/map/pages/' + mapPageId)
        .then(response => {
          this.selectedMapPageData = response.data;
        })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
