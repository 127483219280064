<template>
    <div>
        <b-sidebar id="sidebar-1" title="Jelmagyarázat" right shadow>
        <div class="px-3 py-2" role="tablist">
            <b-card no-body class="mb-1" v-for="attributeValue in attributeValues" :key="attributeValue.mnaId">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <table>
                        <tr>
                            <td>
                                <img :src="attributeValue.mapIconUrl" width="16" height="16"/>
                            </td>
                            <td>
                                {{attributeValue.name}}
                            </td>
                            <td>
                                <b-button v-if="attributeValue.attribImageUrl" block v-b-toggle="'accordion-'+attributeValue.mnaId" variant="info">+</b-button>
                            </td>
                        </tr>
                    </table>
                </b-card-header>
                <b-collapse v-bind:id="'accordion-' + attributeValue.mnaId" role="tabpanel" v-if="attributeValue.attribImageUrl">
                    <b-card-body>
                        <img :src="attributeValue.attribImageUrl" style="width: 100%" />
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
        </b-sidebar>
    </div>
</template>

<script>
export default {
  props: {
    attributeValues: {}
  }
}
</script>

<style>
td { 
    padding: 10px;
}
</style>