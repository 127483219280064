<template>
    <div>
        <b-navbar id="nav" toggleable="lg" type="dark" variant="info">
            <b-navbar-brand href="#">Magyar Néprajzi Atlasz</b-navbar-brand> <!-- TODO: add localization -->

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-form-select v-model="selectedAppArea" @input="$emit('update:selectedAppArea', $event)" :options="appAreaOptions"></b-form-select>
                <b-form-select v-model="selectedMapPage" @input="$emit('update:selectedMapPage', $event)" :options="mapPageOptions"></b-form-select>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-button v-b-toggle.sidebar-1 v-if="selectedMapPage">Jelmagyarázat</b-button>
            </b-navbar-nav>
            <p></p>
        </b-collapse>
    </b-navbar>
    </div>
</template>

<script>
export default {
  props: [
      'appAreas',
      'mapPages'
  ],
  data() {
      return {
          selectedAppArea: null,
          selectedMapPage: null
      }
  },
  computed: {
      appAreaOptions() {
          let appAreaOptions = this.appAreas.map((areaData) =>  {return {value: areaData.appArea, text: areaData.appArea}});
          return [{ value: null, text: 'Térképlap-kategóriák:', disabled: true }].concat(appAreaOptions);
      },
      mapPageOptions() {
          let mapPageOptions = this.mapPages.map((pageData) =>  {return {value: pageData.id, text: pageData.name}});
          return [{ value: null, text: 'Térképlapok a kategóriában:', disabled: true }].concat(mapPageOptions);
      }
  },
  watch: {
    mapPages: function (mapPages) {
        if (mapPages.length > 0) {
            this.selectedMapPage = mapPages[0].mnaId;
        }
    }
  }
}
</script>

<style>
    #nav {
        padding-left: 20px;
        padding-right: 20px;
    }
</style>