<template>
  <div id="root">
    <b-alert style="width: 100%; margin: 0" variant="warning" show>A MNA Online jelenleg tesztelés alatt áll, csak oktatási célra használható. Kutatási felhasználás igénye esetén keresse fel a HUN-REN Néprajztudományi Intézetet.</b-alert>
      <l-map id="map" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker v-for="marker in markers" :key="marker.id" :lat-lng="marker.coords" :icon="icons[marker.attributeValueId]">
            <l-popup :content="marker.popup" />
          </l-marker>
          <template v-if="markerClusters.length > 0">
            <vue2-leaflet-markercluster v-for="cluster in markerClusters" :key="'c' + cluster.id" :options="clusterOptions">
              <l-marker v-for="marker in cluster.markers" :key="marker.id" :lat-lng="marker.coords" :icon="icons[marker.attributeValueId]">
                <l-popup :content="marker.popup" />
              </l-marker>
            </vue2-leaflet-markercluster>
          </template>
      </l-map>
      <Sidebar :attributeValues="mapPageData.attribute_values"/>
  </div>
</template>

    <script>
    import L from 'leaflet';
    import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
    import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster';
    import Sidebar from './Sidebar.vue'
    import { latLng } from "leaflet";
    import {encode} from "html-entities";
    import {capitalizeFirst} from "../utils";

    export default {
      components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        Vue2LeafletMarkercluster,
        Sidebar
      },
      props: ['mapPageData'],
      data () {
        return {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          zoom: 7,
          center: [47.1621, 20.1825], //Szolnok
          icons: {},
          markers: [],
          markerClusters: [],
          clusterOptions: {
            spiderLegPolylineOptions: { weight: 1.5, color: '#222', opacity: 0.7 }
          }
        };
      },
      watch: {
          markers: function(markers) {
            console.log("WATCH");
            console.log(markers);
          },
          mapPageData: function (mapPageData) {
              let markersBeforeClustering = [];
              this.markers = [];
              this.markerClusters = [];
              mapPageData.attribute_values.forEach(attributeValue => {
                  let icon = L.icon({
                        iconUrl: attributeValue.mapIconUrl,
                        iconSize: [16, 16],
                        iconAnchor: [8, 20]
                    });
                  this.icons[attributeValue.mnaId] = icon;
                  attributeValue.location_values.forEach(locationValue => {
                    if (locationValue.map_location.latitude && locationValue.map_location.longitude) {
                      let popupText = `<b>${locationValue.map_location.name}, ${locationValue.map_location.region}</b>`
                      if (locationValue.map_location.currentName) {
                        popupText += `<br/>Jelenleg: ${locationValue.map_location.currentName}, ${locationValue.map_location.country}`;
                      }
                      popupText += `<br/><br/>${capitalizeFirst(encode(attributeValue.name))}`
                      if (attributeValue.attribImageUrl) {
                        popupText += `<img src="${attributeValue.attribImageUrl}" style="width: 100%" />`
                      }
                      markersBeforeClustering.push({id: locationValue.id, mapLocationId: locationValue.map_location.mnaId, attributeValueId: attributeValue.mnaId, coords: latLng(locationValue.map_location.latitude, locationValue.map_location.longitude), popup: popupText})
                    }
                  });
              });

            function clusterMarkers(markersToCluster) {
              let markerClusters = [];
              let markerIdsToDelete = [];
              let clusterId = 0;
              for (let i = 0; i <= markersToCluster.length-1; i++) {
                if (!markerIdsToDelete.includes(markersToCluster[i].id)) {
                  const currentMarker = markersToCluster[i];
                  const markersAtThisLocation = getMarkersWithCoords(markersToCluster, currentMarker.coords);
                  if (markersAtThisLocation.length > 1) {
                    markerClusters.push({
                      id: clusterId,
                      markers: markersAtThisLocation
                    });
                    clusterId++;
                    for (let marker of markersAtThisLocation) {
                      if (!markerIdsToDelete.includes(marker.id)) {
                        markerIdsToDelete.push(marker.id);
                      }
                    }
                  }
                }
              }
              let markers = markersToCluster.filter((ele) => {
                return !markerIdsToDelete.includes(ele.id);
              });
              return {markerClusters, markers};
            }

            function getMarkersWithCoords(markers, coords) {
              let result = [];
              for (let i = 0; i < markers.length; i++) {
                if (markers[i].coords.lat === coords.lat && markers[i].coords.lng === coords.lng) {
                  result.push(markers[i]);
                }
              }
              return result;
            }

            const clusterResult = clusterMarkers(markersBeforeClustering);
            this.markerClusters = clusterResult.markerClusters;
            this.markers = clusterResult.markers;
          }
      }
    }
    </script>

    <style>
    #map, #root {
        height: 100%;
    }
    </style>